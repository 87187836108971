/**
 * @author Irakli Kandelaki
 */

import { useApartmentStore } from "@/store/pinia/apartments";
import { Floor } from "@/store/ts/interfaces/apartmentState.interface";
import { Block } from "../../ProjectsRender/Parts/projectsRender.interface";

export default  (item: Block | Floor, itemType: string): void => {
    const apStore = useApartmentStore();
    if (itemType === 'block') {
        apStore.selectedBlock = item as Block;
    }
}