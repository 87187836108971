import { useApartmentStore } from "@/store/pinia/apartments";
import { Block } from "../../ProjectsRender/Parts/projectsRender.interface";


// SET SELECTED FLOOR
export const setSelectedFloor = async (): Promise<void> => {
    const apStore = useApartmentStore();
    await apStore.getSelectedFloor();
    await apStore.getFloorTypes();
    await apStore.getFloorFlats();
};

// GET SELECTED BLOCK
export const getSelectedBlock = (blockList: Block[]): void => {
    const apStore = useApartmentStore();

    const selectedBlock = blockList?.filter(
        (block: Block) =>
            block?.render[0]?.title === apStore.blockNumber
    );
    if (!selectedBlock.length) {
        apStore.selectedBlock = null;
    } else {
        apStore.selectedBlock = selectedBlock[0];
    }
};